import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { phone } from "phone";
import { useCard } from "../../../../models/card";
import AlertModal from "../../../AlertModal";
import CountrySelect, { countries } from "../../../CountrySelect";

const getUserCountry =
  typeof window !== "undefined"
    ? require("js-user-country").default
    : () => ({ id: "" });

interface Props {
  open: boolean;
  onClose: () => void;
}

const ChangeMobileModal: FC<Props> = ({ open, onClose }) => {
  const { change3DS, fetchCard } = useCard();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const countryCode = (e.target as any).countryCode.value;
            if (!countryCode) {
              return setError("Please select your country code");
            }
            const mobile = (e.target as any).mobile.value;
            if (!mobile) {
              return setError("Please enter your mobile number");
            }
            if (!phone(`${countryCode}${mobile}`).isValid) {
              return setError("Please enter a valid mobile number");
            }
            try {
              setError("");
              setLoading(true);
              await change3DS({ mobile: `${countryCode}${mobile}` });
              setLoading(false);
              setSuccess("Your 3DS mobile number has been changed");
              fetchCard();
              onClose();
            } catch (err: any) {
              setLoading(false);
              setError(err.message);
            }
          }}
        >
          <DialogTitle>Change 3DS Mobile</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Please enter your mobile number
            </DialogContentText>
            <Stack direction={{ md: "row", xs: "column" }}>
              <CountrySelect
                name="countryCode"
                defaultValue={countries.find(
                  (c) => c.code === getUserCountry()?.id
                )}
              />
              <TextField
                sx={{ ml: { md: 2, xs: 0 } }}
                error={!!error}
                fullWidth
                name="mobile"
                placeholder="0000 0000"
              />
            </Stack>
            {!!error && (
              <Typography mt={2} color={(theme) => theme.palette.error.main}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 1 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={loading} size="large" type="submit">
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AlertModal
        open={!!success}
        onClose={() => setSuccess("")}
        title="Success"
        description={success}
        type="success"
      />
    </>
  );
};

export default ChangeMobileModal;
