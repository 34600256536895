import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import { Block, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import LimitProgressBar from "./LimitProgressBar";
import { useCard } from "../../../models/card";
import ChangePasswordModal from "./ChangePasswordModal";
import { useSensitiveInformation } from "../../../models/sensitiveInformation";
import AlertModal from "../../AlertModal";
import useIsMobile from "../../../utils/useIsMobile";
import ChangeMobileModal from "./ChangeMobileModal";

const CardDetailsModal: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const isMobile = useIsMobile();
  const { card, toggleBlockCard, fetchCard } = useCard();
  const {
    sensitiveInformation,
    resetSensitiveInformation,
    fetchSensitiveInformation,
  } = useSensitiveInformation();
  const [loading, setLoading] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [changeMobileModalOpen, setChangeMobileModalOpen] = useState(false);
  const [blockCardModalOpen, setBlockCardModalOpen] = useState(false);
  const [blockCardSuccessModalOpen, setBlockCardSuccessModalOpen] =
    useState(false);
  const [blockCardLoading, setBlockCardLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(false);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
      >
        <DialogContent>
          <Grid container spacing={8}>
            <Grid item lg={6} xs={12}>
              <Stack mb={4} direction="row" justifyContent="space-between">
                <Typography variant="h5">Sensitive Information</Typography>
                <IconButton
                  disabled={loading}
                  onClick={async () => {
                    if (sensitiveInformation.cardNumber) {
                      resetSensitiveInformation();
                    } else {
                      setLoading(true);
                      await fetchSensitiveInformation();
                      setLoading(false);
                    }
                  }}
                >
                  {sensitiveInformation.cardNumber ? (
                    <VisibilityOff />
                  ) : loading ? (
                    <CircularProgress sx={{ mx: 1.5 }} size={25} />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Typography variant="body2">Card Holder</Typography>
                <Typography variant="h6">{card.cardHolder}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Typography variant="body2">Card Number</Typography>
                <Typography variant="h6">
                  {sensitiveInformation?.cardNumber || card.maskedCardNumber}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Typography variant="body2">Expiry Date</Typography>
                <Typography variant="h6">
                  {format(
                    card.expiryDate +
                      new Date(card.expiryDate).getTimezoneOffset() * 60 * 1000,
                    "MM/yy"
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Typography variant="body2">CVV</Typography>
                <Typography variant="h6">
                  {sensitiveInformation?.cvv || "***"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Stack direction="row">
                  <Typography variant="body2">3DS Mobile</Typography>
                  <IconButton
                    sx={{ mt: -1, ml: 1 }}
                    onClick={() => setChangeMobileModalOpen(true)}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
                <Typography variant="h6">{card.mobile}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Stack direction="row">
                  <Typography variant="body2">3DS Password</Typography>
                  <IconButton
                    sx={{ mt: -1, ml: 1 }}
                    onClick={() => setChangePasswordModalOpen(true)}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
                <Typography variant="h6">
                  {sensitiveInformation?.password || "******"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={3}
              >
                <Typography variant="body2">Status</Typography>
                <Typography variant="h6">{card.status}</Typography>
              </Stack>
              {/* <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              mb={3}
            >
              <Typography variant="body2">PIN Code</Typography>
              <Typography variant="h6">******</Typography>
            </Stack> */}
              <Stack alignItems="center">
                <Button
                  size="large"
                  variant="text"
                  color="error"
                  startIcon={<Block />}
                  onClick={() => setBlockCardModalOpen(true)}
                >
                  {card.status === "Blocked"
                    ? "Unblock My Card"
                    : "Block My Card"}
                </Button>
              </Stack>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography variant="h5" mb={4}>
                Spending Limits
              </Typography>
              <Typography variant="body2">Internet Purchase</Typography>
              <LimitProgressBar
                limit={card.limits.internet}
                currency={card.currency}
              />
              <Typography variant="body2">POS Purchase</Typography>
              <LimitProgressBar
                limit={card.limits.posPurchase}
                currency={card.currency}
              />
              <Typography variant="body2">Contactless Purchase</Typography>
              <LimitProgressBar
                limit={card.limits.contactless}
                currency={card.currency}
              />
              {/* <Typography variant="body2">Withdrawal</Typography>
              <LimitProgressBar
                limit={card.limits.withdrawal}
                currency={card.currency}
              /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" size="large" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ChangePasswordModal
        open={changePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
      <ChangeMobileModal
        open={changeMobileModalOpen}
        onClose={() => setChangeMobileModalOpen(false)}
      />
      <AlertModal
        open={blockCardModalOpen}
        type="warning"
        title="Warning"
        description={
          card.status === "Blocked"
            ? "Are you sure to unblock your card?"
            : "Are you sure to block your card? All transactions will be blocked until you unblock it."
        }
        onClose={() => setBlockCardModalOpen(false)}
        loading={blockCardLoading}
        onConfirm={async () => {
          setBlockCardLoading(true);
          await toggleBlockCard();
          await fetchCard();
          setBlockCardLoading(false);
          setBlockCardModalOpen(false);
          setBlockCardSuccessModalOpen(true);
        }}
      />
      <AlertModal
        open={blockCardSuccessModalOpen}
        type="success"
        title="Success"
        description={
          card.status === "Blocked"
            ? "Your card is now blocked"
            : "Your card is now unblocked"
        }
        onClose={() => setBlockCardSuccessModalOpen(false)}
      />
    </>
  );
};

export default CardDetailsModal;
