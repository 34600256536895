import { Button, Container, Stack, Typography } from "@mui/material";
import Image from "next/image";
import useIsMobile from "../../utils/useIsMobile";

const CardDisconnected = ({ connect }: { connect: () => void }) => {
  const isMobile = useIsMobile();
  return (
    <Container sx={{ mt: { md: 16, xs: 4 } }} maxWidth="lg">
      <Stack
        direction={isMobile ? "column-reverse" : "row"}
        alignItems="center"
      >
        <Stack
          flex={1}
          px={{ md: 8, xs: 2 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography mb={2} variant="h2" className="gradient" align="center">
            Fina Card Portal
          </Typography>
          <Typography align="center" variant="body2">
            Web3 Prepaid Card Connected to non-custodial wallets.
          </Typography>
          <Typography mb={8} align="center" variant="body2">
            The one-stop platform to manage your crypto spending in real world.
          </Typography>
          <Button
            size="large"
            onClick={connect}
            startIcon={
              isMobile ? (
                <Image
                  alt="Fina"
                  src={require("../../assets/img/wallets/fina.png")}
                  width={20}
                  height={20}
                  style={{ borderRadius: 4 }}
                />
              ) : (
                <Image
                  alt="Keplr"
                  src={require("../../assets/img/wallets/keplr.png")}
                  width={20}
                  height={20}
                  style={{ borderRadius: 4 }}
                />
              )
            }
          >
            Connect Wallet
          </Button>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          mb={{ md: 0, xs: 4 }}
        >
          <Image
            alt="card"
            width={"100%" as any}
            height={"100%" as any}
            layout="responsive"
            objectFit="contain"
            src={require("../../assets/img/card-hero.png")}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default CardDisconnected;
