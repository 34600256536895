import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import AlertModal from "../../AlertModal";
import Image from "next/image";
import { cardImages } from "../../../utils/constants";
import { useCard } from "../../../models/card";
import { formatNumber, formatPercentage } from "../../../utils/formatNumber";
import { useFinaTokenInfo } from "../../../models/finaTokenInfo";
import StakeModal from "../../StakingSection/StakeModal";
import useIsMobile from "../../../utils/useIsMobile";

interface Props {
  open: boolean;
  onClose: () => void;
}

const UpgradeTierModal: FC<Props> = ({ open, onClose }) => {
  const { card, changeTier, fetchCard } = useCard();
  const { stakingInfo, finaToken, getFinaToken, getStakingInfo } =
    useFinaTokenInfo();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedTier, setSelectedTier] = useState("");
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);

  const myTier = stakingInfo?.finaCardTiers?.tiers[card?.tier?.name];

  const tiers = Object.values(stakingInfo?.finaCardTiers?.tiers).filter(
    (t) => t.tier !== card?.tier?.name
  );

  const missingStakingAmount =
    (stakingInfo?.finaCardTiers?.tiers[selectedTier]?.stakingFinaRequirements ||
      0) - (stakingInfo?.staked || 0);

  useEffect(() => {
    if (!open) {
      setSuccess("");
      setError("");
      setLoading(false);
      setSelectedTier("");
      setIsStakeModalOpen(false);
    }
  }, [open]);

  const isMobile = useIsMobile();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>Tier Upgrade</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Current Tier</Typography>
          <Stack
            my={3}
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
          >
            <Stack
              position="relative"
              width={isMobile ? "100%" : 150}
              mb={isMobile ? 4 : undefined}
            >
              <Image
                alt="card"
                width={"100%" as any}
                height={"100%" as any}
                layout="responsive"
                objectFit="contain"
                src={cardImages[card.tier.name]}
              />
            </Stack>
            <Stack width={isMobile ? "100%" : 250}>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {card.tier.name}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="caption"
                  color={(t) => t.palette.text.secondary}
                >
                  Spending Rewards
                </Typography>
                <Typography
                  variant="caption"
                  ml={4}
                  color={(t) => t.palette.primary.dark}
                  fontWeight="bold"
                >
                  {formatPercentage(card.tier?.spendingRewards)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="caption"
                  color={(t) => t.palette.text.secondary}
                >
                  Monthly Spending Limit
                </Typography>
                <Typography
                  variant="caption"
                  ml={4}
                  color={(t) => t.palette.primary.dark}
                  fontWeight="bold"
                >
                  {formatNumber(card.tier?.spendingLimit)} EUR
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="caption"
                  color={(t) => t.palette.text.secondary}
                >
                  Staking Requirement
                </Typography>
                <Typography
                  variant="caption"
                  ml={4}
                  color={(t) => t.palette.primary.dark}
                  fontWeight="bold"
                >
                  {formatNumber(myTier?.stakingFinaRequirements)} FINA
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Typography mt={3} variant="h6">
            Eligible Tier Upgrade Options
          </Typography>
          {tiers.map((tier) => (
            <Stack
              key={tier.tier}
              my={3}
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              onClick={() => setSelectedTier(tier.tier)}
              sx={(t) => ({
                cursor: "pointer",
                borderRadius: 1,
                p:
                  selectedTier === tier.tier
                    ? `calc(${t.spacing(2)} - 2px) !important`
                    : 2,
                mx: -2,
                mb: -2,
                border:
                  selectedTier === tier.tier
                    ? `2px solid ${t.palette.primary.main} !important`
                    : undefined,
                ":hover": {
                  border: `1px solid ${t.palette.primary.main}`,
                  p: `calc(${t.spacing(2)} - 1px)`,
                },
              })}
            >
              <Stack
                position="relative"
                width={isMobile ? "100%" : 150}
                mb={isMobile ? 4 : undefined}
              >
                <Image
                  alt="card"
                  width={"100%" as any}
                  height={"100%" as any}
                  layout="responsive"
                  objectFit="contain"
                  src={cardImages[tier.tier]}
                />
              </Stack>
              <Stack width={isMobile ? "100%" : 250}>
                <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                  {tier.tier}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography
                    variant="caption"
                    color={(t) => t.palette.text.secondary}
                  >
                    Spending Rewards
                  </Typography>
                  <Typography
                    variant="caption"
                    ml={4}
                    color={(t) => t.palette.primary.dark}
                    fontWeight="bold"
                  >
                    {formatPercentage(tier.spendingRewards)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography
                    variant="caption"
                    color={(t) => t.palette.text.secondary}
                  >
                    Monthly Spending Limit
                  </Typography>
                  <Typography
                    variant="caption"
                    ml={4}
                    color={(t) => t.palette.primary.dark}
                    fontWeight="bold"
                  >
                    {formatNumber(tier.spendingLimit)} EUR
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography
                    variant="caption"
                    color={(t) => t.palette.text.secondary}
                  >
                    Staking Requirement
                  </Typography>
                  <Typography
                    variant="caption"
                    ml={4}
                    color={(t) => t.palette.primary.dark}
                    fontWeight="bold"
                  >
                    {formatNumber(tier.stakingFinaRequirements)} FINA
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </DialogContent>
        <DialogActions
          sx={
            isMobile
              ? { flexDirection: "column" }
              : { justifyContent: "space-between" }
          }
        >
          <Typography
            color={(t) => t.palette.primary.dark}
            fontWeight="bold"
            ml={isMobile ? undefined : 2}
            my={isMobile ? 1 : undefined}
          >
            You staked: {formatNumber(stakingInfo.staked)} FINA
          </Typography>
          <Stack direction="row">
            <Button
              sx={{ mr: 1 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || !selectedTier}
              size="large"
              onClick={async () => {
                if (missingStakingAmount <= 0) {
                  setLoading(true);
                  try {
                    await changeTier(finaToken?.viewingKey, selectedTier);
                    await fetchCard();
                    setLoading(false);
                    setSuccess(
                      "You have successfully changed your Fina Card tier!"
                    );
                  } catch (err: any) {
                    setLoading(false);
                    setError(err.message);
                  }
                } else {
                  setIsStakeModalOpen(true);
                }
              }}
            >
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : missingStakingAmount <= 0 || !selectedTier ? (
                "Confirm"
              ) : (
                "Stake More"
              )}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <AlertModal
        open={!!success || !!error}
        onClose={() => {
          setSuccess("");
          setError("");
        }}
        title={error ? "Error" : "Success"}
        description={error || success}
        type={error ? "error" : "success"}
      />
      <StakeModal
        open={isStakeModalOpen}
        onClose={() => setIsStakeModalOpen(false)}
        max={finaToken.balance}
        lockUpDays={stakingInfo.lockUpDays}
        onSuccess={async () => {
          await getFinaToken(true);
          await getStakingInfo(true);
        }}
        defaultAmount={missingStakingAmount || undefined}
      />
    </>
  );
};

export default UpgradeTierModal;
