import {
  ButtonBase,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Spend from "@mui/icons-material/SwapHoriz";
import Topup from "@mui/icons-material/AddCard";
import { format, formatRelative } from "date-fns";
import { formatNumber } from "../../utils/formatNumber";
import Image from "next/image";
import { useCard } from "../../models/card";

const TransactionItem = ({
  type,
  name,
  amount,
  date,
  icon,
  longName,
  isNewDay,
  onClick,
  hideBorder,
  hideAmount,
  status,
  style,
}: {
  type: "spend" | "topup" | "skeleton";
  name: string;
  amount: number;
  date: number;
  icon?: string;
  longName?: string;
  isNewDay?: boolean;
  onClick?: () => void;
  hideBorder?: boolean;
  hideAmount?: boolean;
  status?: string;
  style?: any;
}) => {
  const theme = useTheme();
  const { card } = useCard();
  return (
    <div style={style}>
      <Stack pr={2}>
        {isNewDay && (
          <Typography
            variant="h6"
            sx={(theme) => ({
              textTransform: "capitalize",
              mt: 2,
              color: theme.palette.text.secondary,
            })}
          >
            {formatRelative(date, Date.now()).split(" at")[0]}
          </Typography>
        )}
        <ButtonBase
          disabled={!onClick}
          sx={{ display: "block", textAlign: "left" }}
          onClick={onClick}
        >
          <Stack
            py={2}
            direction="row"
            alignItems="center"
            borderBottom={
              hideBorder
                ? undefined
                : (theme) => `1px solid ${theme.palette.divider}`
            }
            sx={
              onClick
                ? (theme) => ({
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  })
                : undefined
            }
          >
            {icon && type === "spend" && (
              <Image
                style={{ borderRadius: theme.shape.borderRadius }}
                alt={name}
                width={35}
                height={35}
                src={icon}
              />
            )}
            {!icon && type === "spend" && (
              <Spend color="primary" fontSize="large" />
            )}
            {type === "topup" && <Topup color="primary" fontSize="large" />}
            {!icon && type === "skeleton" && (
              <Skeleton variant="circular" width={48} height={48} />
            )}
            <Stack ml={2} flex={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="h6"
                  mr={4}
                  flex={1}
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                    overflow: "hidden",
                    maxWidth: hideAmount ? "none" : { md: 240, xs: 160 },
                  }}
                >
                  {type !== "skeleton" ? name : <Skeleton width={100} />}
                </Typography>
                {!hideAmount && (
                  <Typography
                    variant="h6"
                    align="right"
                    color={(theme) =>
                      amount > 0 ? theme.palette.success.light : undefined
                    }
                    sx={status === "Canceled" ? { opacity: 0.3 } : undefined}
                  >
                    {amount < 0 &&
                      `- ${card.currency} ${formatNumber(-amount)}`}
                    {amount > 0 && `${card.currency} ${formatNumber(amount)}`}
                    {type === "skeleton" && <Skeleton width={100} />}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                    overflow: "hidden",
                  }}
                  color={
                    status === "Canceled"
                      ? (theme) => theme.palette.error.main
                      : undefined
                  }
                >
                  {type === "spend" &&
                    (status === "Canceled"
                      ? "Canceled"
                      : longName || "Spending")}
                  {type === "topup" && "Top Up"}
                  {type === "skeleton" && <Skeleton width={100} />}
                </Typography>
                {!hideAmount && (
                  <Typography variant="body2" align="right">
                    {type !== "skeleton" ? (
                      format(date, "h:mm a")
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </ButtonBase>
      </Stack>
    </div>
  );
};

export default TransactionItem;
