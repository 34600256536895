import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useCard } from "../../models/card";
import { formatNumber } from "../../utils/formatNumber";
import transformSpendingChartData from "../../utils/transformSpendingChartData";
import TopupModal from "./TopupModal";
import AlertModal from "../AlertModal";
import { useFinaTokenInfo } from "../../models/finaTokenInfo";

const CardBalance = () => {
  const theme = useTheme();
  const { card, fetchCard, claimRewards } = useCard();
  const { getFinaToken } = useFinaTokenInfo();
  const [isClaiming, setIsClaiming] = useState(false);
  const [topupModalOpen, setTopupModalOpen] = useState(false);

  const [isClaimSuccess, setIsClaimSuccess] = useState(false);
  const [claimError, setClaimError] = useState("");

  const data = useMemo(
    () => transformSpendingChartData(card.transactions),
    [card]
  );

  return (
    <>
      <Stack p={{ md: 4, xs: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={2}
        >
          <Typography variant="h5">Balance</Typography>
          <Button disabled={!card.id} onClick={() => setTopupModalOpen(true)}>
            Top Up
          </Button>
        </Stack>
        <Typography mb={4} variant="h3">
          {card.id ? (
            `${card.currency} ${formatNumber(card.balance)}`
          ) : (
            <Skeleton width="100%" />
          )}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={2}
          mt={4}
        >
          <Typography variant="h5">Spending Rewards</Typography>
          <Button
            disabled={!card.id || isClaiming || card.rewards === 0}
            onClick={async () => {
              try {
                setIsClaiming(true);
                await claimRewards();
                await fetchCard();
                await getFinaToken(true);
                setIsClaimSuccess(true);
                setIsClaiming(false);
              } catch (err: any) {
                setIsClaiming(false);
                setClaimError(err.message);
              }
            }}
          >
            {isClaiming ? <CircularProgress size={24} /> : "Claim"}
          </Button>
        </Stack>
        <Typography mb={4} variant="h3">
          {card.id ? (
            `${formatNumber(card.rewards, 2)} FINA`
          ) : (
            <Skeleton width="100%" />
          )}
        </Typography>
        <Divider />
        <Typography mt={4} mb={2} variant="h5">
          Spending
        </Typography>
        {card.id ? (
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              style={{
                fontFamily: "Roboto, sans-serif",
              }}
              data={data}
              margin={{ top: 40 }}
            >
              <XAxis
                stroke={theme.palette.text.secondary}
                dataKey="name"
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip
                cursor={false}
                offset={0}
                wrapperStyle={{
                  outline: "none",
                }}
                contentStyle={{
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.primary.dark,
                  padding: theme.spacing(0, 2),
                  marginLeft: "-50%",
                  marginRight: "50%",
                }}
                itemStyle={{
                  color: theme.palette.background.default,
                }}
                allowEscapeViewBox={{ x: true, y: true }}
                position={{ y: 0 }}
                formatter={(value) => [formatNumber(Number(value))]}
                labelFormatter={() => ""}
              />
              <Bar
                dataKey="value"
                fill={theme.palette.primary.light}
                barSize={28}
                radius={theme.shape.borderRadius}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton variant="rounded" width="100%" height={300} />
        )}
      </Stack>
      <TopupModal
        open={topupModalOpen}
        onClose={() => setTopupModalOpen(false)}
      />
      <AlertModal
        open={isClaimSuccess || !!claimError}
        onClose={() => {
          setIsClaimSuccess(false);
          setClaimError("");
        }}
        type={claimError ? "error" : "success"}
        title={claimError ? "Error" : "Success"}
        description={claimError || "Spending rewards claimed successfully!"}
      />
    </>
  );
};

export default CardBalance;
