import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useCard } from "../../../models/card";
import { useSensitiveInformation } from "../../../models/sensitiveInformation";
import AlertModal from "../../AlertModal";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal: FC<Props> = ({ open, onClose }) => {
  const { change3DS } = useCard();
  const { resetSensitiveInformation } = useSensitiveInformation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const password = (e.target as any).password.value;
            const confirmPassword = (e.target as any).confirmPassword.value;
            if (password.length < 8 || password.length > 36) {
              return setError("3DS Password must have 8-36 characters");
            }
            if (password !== confirmPassword) {
              return setError("Confirm password does not match");
            }
            try {
              setError("");
              setLoading(true);
              await change3DS({ password });
              setLoading(false);
              setSuccess("Your 3DS Password has been changed");
              resetSensitiveInformation();
              onClose();
            } catch (err: any) {
              setLoading(false);
              setError(err.message);
            }
          }}
        >
          <DialogTitle>Change 3DS Password</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Please enter a password with 8-36 characters
            </DialogContentText>
            <TextField
              sx={{ mb: 2 }}
              error={!!error}
              fullWidth
              name="password"
              type="password"
              placeholder="New Password"
            />
            <TextField
              error={!!error}
              fullWidth
              name="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
            />
            {!!error && (
              <Typography mt={2} color={(theme) => theme.palette.error.main}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 1 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={loading} size="large" type="submit">
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AlertModal
        open={!!success}
        onClose={() => setSuccess("")}
        title="Success"
        description={success}
        type="success"
      />
    </>
  );
};

export default ChangePasswordModal;
