import { Container, Grid, Stack } from "@mui/material";
import { useState } from "react";
import CardBalance from "../components/CardBalance";
import CardInfo from "../components/CardInfo";
import CardTransactions from "../components/CardTransactions";
import Navbar from "../components/Navbar";
import { useAccount } from "../models/account";
import { useCard } from "../models/card";
import useIsMobile from "../utils/useIsMobile";
import { useFinaTokenInfo } from "../models/finaTokenInfo";
import CardDisconnected from "../components/CardDisconnected";
import CardRegister from "../components/CardRegister";
import AlertModal from "../components/AlertModal";

export default function Card() {
  const isMobile = useIsMobile();
  const { stakingInfo } = useFinaTokenInfo();
  const { account, connect } = useAccount();
  const { card } = useCard();

  const [successMessage, setSuccessMessage] = useState("");

  const isFinaCardTiersLoaded =
    Object.keys(stakingInfo.finaCardTiers.tiers || {}).length > 0;

  return (
    <>
      <Stack
        bgcolor={(theme) => theme.palette.background.default}
        minHeight="100vh"
      >
        <Navbar activeTab="/" />
        {!card.id && !account.address && <CardDisconnected connect={connect} />}
        {account.address && card.notRegistered && isFinaCardTiersLoaded && (
          <CardRegister setSuccessMessage={setSuccessMessage} />
        )}
        {account.address && (!isFinaCardTiersLoaded || !card.notRegistered) && (
          <Container sx={{ mt: 4 }} maxWidth="xl">
            <Grid container spacing={isMobile ? 4 : 0}>
              <Grid item md={4} xs={12}>
                <CardInfo />
              </Grid>
              <Grid
                sx={
                  isMobile
                    ? undefined
                    : (theme) => ({
                        borderLeft: `1px solid ${theme.palette.divider}`,
                        borderRight: `1px solid ${theme.palette.divider}`,
                      })
                }
                item
                md={4}
                xs={12}
              >
                <CardBalance />
              </Grid>
              <Grid item md={4} xs={12}>
                <CardTransactions />
              </Grid>
            </Grid>
          </Container>
        )}
      </Stack>
      <AlertModal
        open={!!successMessage}
        onClose={() => setSuccessMessage("")}
        title="Success"
        description={successMessage}
        type="success"
      />
    </>
  );
}
