import { addMonths, format, getMonth } from "date-fns";
import { times } from "lodash";
import { Transaction } from "../models/card";

const transformSpendingChartData = (txs: Transaction[]) => {
  const now = Date.now();
  const data = times(6).map((i) => ({
    name: format(addMonths(now, -i), "MMM"),
    value: 0,
  }));
  txs
    .filter((tx) => tx.status !== "Canceled")
    .forEach((tx) => {
      let diff = getMonth(now) - getMonth(tx.date);
      if (diff < 0) {
        diff += 12;
      }
      if (data[diff] && tx.amount < 0) {
        data[diff].value += -tx.amount;
      }
    });
  return data.reverse();
};

export default transformSpendingChartData;
