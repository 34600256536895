import { LinearProgress, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Limit } from "../../../models/card";
import { formatNumber } from "../../../utils/formatNumber";

const LimitProgressBar: FC<{ limit: Limit; currency: string }> = ({
  limit,
  currency,
}) => {
  return (
    <Stack flex={1} mb={2} ml={4}>
      <LinearProgress
        variant="determinate"
        value={(100 * limit.daily.used) / limit.daily.limit}
        sx={{ mt: 2 }}
      />
      <Stack direction="row" justifyContent="space-between" mt={0.5}>
        <Typography variant="body2">Daily</Typography>
        <Typography variant="body2">
          {currency} {formatNumber(limit.daily.used)} /{" "}
          {formatNumber(limit.daily.limit)}
        </Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={(100 * limit.monthly.used) / limit.monthly.limit}
        sx={{ mt: 2 }}
      />
      <Stack direction="row" justifyContent="space-between" mt={0.5}>
        <Typography variant="body2">Monthly</Typography>
        <Typography variant="body2">
          {currency} {formatNumber(limit.monthly.used)} /{" "}
          {formatNumber(limit.monthly.limit)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LimitProgressBar;
