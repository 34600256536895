import {
  Box,
  Button,
  CircularProgress,
  Container,
  DialogContentText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import useIsMobile from "../../utils/useIsMobile";
import Image from "next/image";
import { useFinaTokenInfo } from "../../models/finaTokenInfo";
import { useEffect, useState } from "react";
import { cardColors, cardImages } from "../../utils/constants";
import { formatNumber, formatPercentage } from "../../utils/formatNumber";
import CountrySelect, { countries } from "../CountrySelect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import StakeModal from "../StakingSection/StakeModal";
import phone from "phone";
import { useCard } from "../../models/card";
import { useRecoilState } from "recoil";
import { colorModeState } from "../../models/misc";

const getUserCountry =
  typeof window !== "undefined"
    ? require("js-user-country").default
    : () => ({ id: "" });

const StepperTitle = ({
  number,
  title,
  disabled,
}: {
  number: number;
  title: string;
  disabled?: boolean;
}) => {
  return (
    <Stack direction="row" mb={4}>
      <Stack
        width={(theme) => theme.spacing(4)}
        height={(theme) => theme.spacing(4)}
        bgcolor={(theme) =>
          disabled ? theme.palette.grey[400] : theme.palette.primary.main
        }
        borderRadius="100%"
        justifyContent="center"
        alignItems="center"
        mr={4}
      >
        <Typography
          fontSize={18}
          color={(theme) => theme.palette.background.default}
        >
          {number}
        </Typography>
      </Stack>
      <Typography
        color={(theme) =>
          disabled ? theme.palette.grey[400] : theme.palette.primary.main
        }
        variant="h5"
      >
        {title}
      </Typography>
    </Stack>
  );
};

const CardRegister = ({
  setSuccessMessage,
}: {
  setSuccessMessage: (s: string) => void;
}) => {
  const isMobile = useIsMobile();
  const [colorMode] = useRecoilState(colorModeState);
  const { stakingInfo, finaToken, getFinaToken, getStakingInfo } =
    useFinaTokenInfo();
  const { createCard, fetchCard } = useCard();
  const tiers = Object.values(stakingInfo?.finaCardTiers?.tiers || {}).sort(
    (a, b) => b.stakingEurRequirements - a.stakingEurRequirements
  );

  const [tier, setTier] = useState(tiers[1]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);

  useEffect(() => {
    tiers.length && setTier(tiers[1]);
  }, [tiers.length]);

  const missingStakingAmount = !!tier
    ? Math.max(tier.stakingFinaRequirements - stakingInfo?.staked, 0)
    : 0;

  const defaultCountryCode = countries.find(
    (c) => c.code === getUserCountry()?.id
  );

  const whitelistedAndSilver =
    stakingInfo?.finaCardTiers?.myTier?.whitelisted && tier?.tier !== "black";

  return (
    !!tier &&
    (stakingInfo.finaCardTiers.quota > stakingInfo.finaCardTiers.registered ? (
      <>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!whitelistedAndSilver && missingStakingAmount > 0) {
              return setIsStakeModalOpen(true);
            }
            const nameInput = (e.target as any).name.value;
            if (!nameInput) {
              return setError("Please enter your name");
            }
            const countryCodeInput = (e.target as any).countryCode.value;
            if (!countryCodeInput) {
              return setError("Please select your country code");
            }
            const mobileInput = (e.target as any).mobile.value;
            if (!mobileInput) {
              return setError("Please enter a valid mobile number");
            }
            if (!phone(`${countryCodeInput}${mobileInput}`).isValid) {
              return setError("Please enter a valid mobile number");
            }
            const passwordInput = (e.target as any).password.value;
            if (
              !passwordInput ||
              passwordInput.length < 8 ||
              passwordInput.length > 36
            ) {
              return setError("Please enter a 3DS Password with 8-36 digits");
            }
            try {
              setLoading(true);
              await createCard(
                nameInput,
                `${countryCodeInput}${mobileInput}`,
                passwordInput,
                finaToken.viewingKey,
                tier.tier
              );
              await fetchCard();
              setLoading(false);
              setSuccessMessage("Fina Card created successfully!");
              setError("");
            } catch (err: any) {
              setLoading(false);
              setError(err.message);
            }
          }}
        >
          <Container sx={{ mt: { md: 8, xs: 4 } }} maxWidth="lg">
            <Stack
              direction={isMobile ? "column" : "row"}
              mb={{ md: 0, xs: 4 }}
            >
              <Stack flex={1} px={{ md: 8, xs: 2 }}>
                <Stack maxWidth={400}>
                  <Image
                    alt="card"
                    width={"100%" as any}
                    height={"100%" as any}
                    layout="responsive"
                    objectFit="contain"
                    src={cardImages[tier.tier]}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    my={3}
                  >
                    <Typography variant="body2">Tier</Typography>
                    <Typography variant="h6" textTransform="capitalize">
                      {tier.tier}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={3}
                  >
                    <Typography variant="body2">Spending Rewards</Typography>
                    <Typography variant="h6">
                      {formatPercentage(tier.spendingRewards)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={3}
                  >
                    <Typography variant="body2">
                      Monthly Spending Limit
                    </Typography>
                    <Typography variant="h6">
                      {formatNumber(tier.spendingLimit)} EUR
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={3}
                  >
                    <Typography variant="body2">Staking Requirement</Typography>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="h6">
                        {whitelistedAndSilver
                          ? "Whitelisted"
                          : `${formatNumber(
                              tier.stakingFinaRequirements
                            )} FINA`}
                      </Typography>
                      {!whitelistedAndSilver && missingStakingAmount > 0 ? (
                        <CancelRounded
                          style={{ marginLeft: 8 }}
                          color="error"
                        />
                      ) : (
                        <CheckCircle
                          style={{ marginLeft: 8 }}
                          color="success"
                        />
                      )}
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={3}
                  >
                    <Typography variant="body2">You Staked</Typography>
                    <Typography variant="h6">
                      {formatNumber(stakingInfo.staked)} FINA
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack flex={1}>
                <StepperTitle number={1} title="Card Tier" />
                <Stack direction="row" mb={8} ml={8}>
                  {tiers.map((t) => (
                    <Box
                      key={t.tier}
                      p={"2px"}
                      border={1}
                      borderRadius={1}
                      borderColor={(theme) =>
                        tier.tier === t.tier
                          ? cardColors[tier.tier]
                          : theme.palette.background.default
                      }
                      sx={{ cursor: "pointer" }}
                      onClick={() => setTier(t)}
                      mr={6}
                    >
                      <Box
                        bgcolor={cardColors[t.tier]}
                        width={(theme) => theme.spacing(4)}
                        height={(theme) => theme.spacing(4)}
                        borderRadius={1}
                      />
                    </Box>
                  ))}
                </Stack>
                <StepperTitle
                  number={2}
                  title="Card Information"
                  disabled={!whitelistedAndSilver && missingStakingAmount > 0}
                />
                <Stack ml={8}>
                  <DialogContentText mb={1}>Name</DialogContentText>
                  <TextField
                    error={error === "Please enter your name"}
                    fullWidth
                    name="name"
                    placeholder="Satoshi Nakamoto"
                    disabled={!whitelistedAndSilver && missingStakingAmount > 0}
                  />
                  <DialogContentText mb={1} mt={3}>
                    Mobile
                  </DialogContentText>
                  <Stack direction={{ md: "row", xs: "column" }}>
                    <CountrySelect
                      name="countryCode"
                      error={error === "Please select your country code"}
                      defaultValue={defaultCountryCode}
                      disabled={
                        !whitelistedAndSilver && missingStakingAmount > 0
                      }
                    />
                    <TextField
                      sx={{ ml: { md: 2, xs: 0 } }}
                      error={error === "Please enter a valid mobile number"}
                      fullWidth
                      name="mobile"
                      placeholder="0000 0000"
                      disabled={
                        !whitelistedAndSilver && missingStakingAmount > 0
                      }
                    />
                  </Stack>
                  <DialogContentText mt={3} mb={1}>
                    3DS Password
                  </DialogContentText>
                  <TextField
                    type={passwordVisible ? "text" : "password"}
                    error={
                      error === "Please enter a 3DS Password with 8-36 digits"
                    }
                    fullWidth
                    name="password"
                    placeholder="********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setPasswordVisible((v) => !v)}
                          >
                            {passwordVisible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={!whitelistedAndSilver && missingStakingAmount > 0}
                  />
                  {!!error && (
                    <Typography
                      mt={2}
                      color={(theme) => theme.palette.error.main}
                    >
                      {error}
                    </Typography>
                  )}
                  <Button
                    sx={{
                      width: 150,
                      mt: 4,
                      alignSelf: "center",
                    }}
                    disabled={loading}
                    size="large"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress sx={{ mx: 1.5 }} size={25} />
                    ) : !whitelistedAndSilver && missingStakingAmount > 0 ? (
                      "Stake FINA"
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </form>
        <StakeModal
          open={isStakeModalOpen}
          onClose={() => setIsStakeModalOpen(false)}
          max={finaToken.balance}
          lockUpDays={stakingInfo.lockUpDays}
          onSuccess={async () => {
            await getFinaToken(true);
            await getStakingInfo(true);
          }}
          defaultAmount={missingStakingAmount || undefined}
        />
      </>
    ) : (
      <Container
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box my={4} width="100%" maxWidth={400}>
          <Box className="responsive-image-container">
            <Image
              fill
              src={
                colorMode === "dark"
                  ? require("../../assets/img/sold-out-dark.png")
                  : require("../../assets/img/sold-out-light.png")
              }
              alt="sold out"
            />
          </Box>
        </Box>
        <Typography mb={2} align="center" variant="h4">
          We are sold out.
        </Typography>
        <Typography align="center" variant="body2">
          Our Fina Card quota is currently full. Check back soon for the next
          batch.
        </Typography>
      </Container>
    ))
  );
};

export default CardRegister;
