/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";

export interface AssetType {
  symbol: string;
  denom: string;
  type: "native" | "ibc" | "snip20" | "cw20";
}

// TODO: dont hard code
export const assets: AssetType[] = [
  { symbol: "SCRT", denom: "uscrt", type: "native" },
  {
    symbol: "sSCRT",
    denom: "secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek",
    type: "snip20",
  },
  {
    symbol: "SILK",
    denom: "secret1fl449muk5yq8dlad7a22nje4p5d2pnsgymhjfd",
    type: "snip20",
  },
];

export default function AssetSelect({
  disabled,
  ...props
}: Partial<AutocompleteProps<AssetType, false | undefined, true, undefined>>) {
  return (
    <Autocomplete<AssetType, false | undefined, true>
      options={assets}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.symbol}
      componentsProps={{
        popper: { sx: { left: 0 } },
      }}
      filterOptions={createFilterOptions({
        stringify: (option) => option.symbol,
      })}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.symbol}
        >
          {option.symbol}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          disabled={disabled}
        />
      )}
      defaultValue={assets[0]}
      disabled={disabled}
      {...props}
    />
  );
}
