import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAccount } from "./account";

export interface TopupInfo {
  topupAddress: string;
  maxTopupAmount: number;
  minTopupAmount: number;
  initialTopupValue: number;
  rate: number;
  feeRate: number;
  denom: string;
  decimals: number;
  gasBuffer: number;
  gasPrice: {
    amount: string;
    denom: string;
  };
  type: "native" | "ibc" | "snip20" | "cw20";
  codeHash: string;
}

type TopupInfoParams = {
  chainId: string;
  topupAsset: string;
  currency: string;
};

const emptyTopupInfo: TopupInfo = {
  topupAddress: "",
  maxTopupAmount: 0,
  minTopupAmount: 0,
  initialTopupValue: 0,
  rate: 0,
  feeRate: 0,
  denom: "",
  decimals: 0,
  gasBuffer: 0,
  gasPrice: {
    amount: "",
    denom: "",
  },
  type: "native",
  codeHash: "",
};

export const topupInfoState = atom<TopupInfo>({
  key: "topupInfo",
  default: emptyTopupInfo,
});

export const useTopupInfo = () => {
  const { account } = useAccount();
  const [topupInfo, setTopupInfo] = useRecoilState(topupInfoState);

  const fetchTopupInfo = useCallback(
    async (params: TopupInfoParams) => {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_CARD_API_URL}/getTopupInfo`,
        {
          method: "POST",
          body: JSON.stringify({ ...account, ...params }),
        }
      ).then((r) => r.json());
      if (result.topupAddress) {
        setTopupInfo(result);
      }
    },
    [account, setTopupInfo]
  );

  const resetTopupInfo = useCallback(() => {
    setTopupInfo(emptyTopupInfo);
  }, [setTopupInfo]);

  return { topupInfo, fetchTopupInfo, resetTopupInfo };
};
