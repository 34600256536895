import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import TransactionItem from "./TransactionItem";
import { useCallback, useMemo, useState } from "react";
import { get, times } from "lodash";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import { CSVLink } from "react-csv";
import { useCard, Transaction } from "../../models/card";
import useIsMobile from "../../utils/useIsMobile";
import { differenceInCalendarDays } from "date-fns";
import TransactionModal from "./TransactionModal";
import { formatNumber } from "../../utils/formatNumber";

const filterTxByText = (tx: Transaction, text: string) => {
  const fields = ["description", "merchant.name", "amount"];
  return fields
    .map((f) =>
      String(get(tx, f, "")).toLowerCase().includes(text.toLowerCase())
    )
    .reduce((a, b) => a || b, false);
};

const CardTransactions = () => {
  const { card } = useCard();
  const isMobile = useIsMobile();
  const [searchText, setSearchText] = useState("");
  const [activeTx, setActiveTx] = useState<Transaction>();
  const txs = useMemo(
    () => card.transactions.filter((tx) => filterTxByText(tx, searchText)),
    [card, searchText]
  );

  const rowRenderer = useCallback(
    ({ key, index, style }: any) => (
      <TransactionItem
        key={key}
        style={style}
        onClick={() => setActiveTx(txs[index])}
        type={txs[index].hash && txs[index].amount > 0 ? "topup" : "spend"}
        name={
          txs[index].hash && txs[index].amount > 0
            ? txs[index].description
            : txs[index].merchant.name
        }
        longName={
          txs[index].merchant.name === txs[index].merchant.longName
            ? ""
            : txs[index].merchant.longName
        }
        status={txs[index].status}
        icon={txs[index].merchant.icon}
        amount={txs[index].amount}
        date={txs[index].date}
        isNewDay={
          index === 0 ||
          differenceInCalendarDays(txs[index].date, txs[index - 1].date) !== 0
        }
      />
    ),
    [txs]
  );

  return (
    <>
      <Stack p={{ md: 4, xs: 1 }} pb={{ md: 0, xs: 0 }}>
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h5">Transactions</Typography>
          {card.id ? (
            <CSVLink
              style={{ textDecoration: "none" }}
              data={card.transactions.map((tx) => ({
                date: new Date(tx.date).toISOString(),
                merchant: tx.merchant.longName,
                amount: tx.amount,
                currency: card.currency,
                fx_amount: tx.fx.amount,
                fx_currency: tx.fx.currency,
                status: tx.status,
                description: tx.description,
              }))}
              filename="Fina Card Transactions.csv"
            >
              <Button>Export</Button>
            </CSVLink>
          ) : (
            <Button disabled>Export</Button>
          )}
        </Stack>
        <TextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        {card.id ? (
          isMobile ? (
            <WindowScroller>
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <List
                  autoHeight
                  height={height}
                  width={window.outerWidth - 32}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  rowHeight={({ index }) =>
                    index === 0 ||
                    differenceInCalendarDays(
                      txs[index].date,
                      txs[index - 1].date
                    ) !== 0
                      ? 130.75
                      : 85.31
                  }
                  rowRenderer={rowRenderer}
                  rowCount={card.id ? txs.length : 3}
                />
              )}
            </WindowScroller>
          ) : (
            <Stack height="calc(100vh - 232px)" mr={-2}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    rowHeight={({ index }) =>
                      index === 0 ||
                      differenceInCalendarDays(
                        txs[index].date,
                        txs[index - 1].date
                      ) !== 0
                        ? 130.75
                        : 85.31
                    }
                    rowRenderer={rowRenderer}
                    rowCount={card.id ? txs.length : 3}
                  />
                )}
              </AutoSizer>
            </Stack>
          )
        ) : (
          <Stack mr={-2}>
            {times(3).map((i) => (
              <TransactionItem
                key={i}
                type="skeleton"
                name=""
                amount={0}
                date={0}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <TransactionModal
        open={!!activeTx}
        onClose={() => setActiveTx(undefined)}
        transaction={activeTx}
      />
    </>
  );
};

export default CardTransactions;
