import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import Image from "next/image";
import { useState } from "react";
import { useCard } from "../../models/card";
import { formatPercentage } from "../../utils/formatNumber";
import CardDetailsModal from "./CardDetailsModal";
import { cardImages } from "../../utils/constants";
import UpgradeTierModal from "./UpgradeTierModal";

const CardInfo = () => {
  const { card } = useCard();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  return (
    <>
      <Stack p={{ md: 4, xs: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={4}
        >
          <Typography variant="h5">Card Info</Typography>
          <Button disabled={!card.id} onClick={() => setDetailsOpen(true)}>
            Details
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={3}
        >
          <Typography variant="body2">Status</Typography>
          <Typography variant="h6">
            {card.status || <Skeleton width={100} />}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={3}
        >
          <Typography variant="body2">Currency</Typography>
          <Typography variant="h6">
            {card.currency || <Skeleton width={100} />}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={3}
        >
          <Typography variant="body2">Tier</Typography>
          <Typography sx={{ textTransform: "capitalize" }} variant="h6">
            {card.tier.name || <Skeleton width={100} />}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={3}
        >
          <Typography variant="body2">Spending Rewards</Typography>
          <Typography variant="h6">
            {card.id ? (
              formatPercentage(card.tier?.spendingRewards)
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{ alignSelf: "flex-end", mb: 3 }}
          disabled={!card.id}
          onClick={() => setUpgradeOpen(true)}
        >
          Upgrade Tier
        </Button>
        {cardImages[card?.tier?.name] ? (
          <Stack position="relative">
            <Image
              alt="card"
              width={"100%" as any}
              height={"100%" as any}
              layout="responsive"
              objectFit="contain"
              src={cardImages[card.tier.name]}
            />
            <Stack position="absolute" bottom={24} left={24}>
              <Typography
                variant="h5"
                color={(theme) => theme.palette.common.white}
              >
                {card.maskedCardNumber}
              </Typography>
              <Typography mb={4} color={(theme) => theme.palette.common.white}>
                Valid Thru:{" "}
                {format(
                  card.expiryDate +
                    new Date(card.expiryDate).getTimezoneOffset() * 60 * 1000,
                  "MM/yy"
                )}
              </Typography>
              <Typography color={(theme) => theme.palette.common.white}>
                {card.cardHolder}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Skeleton variant="rounded" width="100%">
            <Image
              alt="card"
              width={"100%" as any}
              height={"100%" as any}
              layout="responsive"
              objectFit="contain"
              src={cardImages.black}
            />
          </Skeleton>
        )}
      </Stack>
      <CardDetailsModal
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
      />
      <UpgradeTierModal
        open={upgradeOpen}
        onClose={() => setUpgradeOpen(false)}
      />
    </>
  );
};

export default CardInfo;
