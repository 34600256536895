import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useCard } from "../../../models/card";
import { useSensitiveInformation } from "../../../models/sensitiveInformation";
import { TopupInfo } from "../../../models/topupInfo";
import { formatNumber } from "../../../utils/formatNumber";
import AlertModal from "../../AlertModal";
import AssetSelect, { AssetType } from "./AssetSelect";

interface Props {
  open: boolean;
  onClose: () => void;
  topupAsset: AssetType;
  setTopupAsset: (t: AssetType) => void;
  currency: string;
  topupInfo: TopupInfo;
}

const RetrieveTopUpModal: FC<Props> = ({
  open,
  onClose,
  topupAsset,
  setTopupAsset,
  currency,
  topupInfo,
}) => {
  const { topup, fetchCard } = useCard();
  const { resetSensitiveInformation } = useSensitiveInformation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const txHash = (e.target as any).txHash.value;
            try {
              setError("");
              setLoading(true);
              const result = await topup(
                topupInfo,
                "0",
                topupAsset.symbol,
                currency,
                txHash
              );
              await fetchCard();
              setLoading(false);
              setSuccess(
                `You have successfully topped up ${
                  result.currency
                } ${formatNumber(result.topupValue)} to your Fina Card!`
              );
              resetSensitiveInformation();
              onClose();
            } catch (err: any) {
              setLoading(false);
              setError(err.message);
            }
          }}
        >
          <DialogTitle>Retrieve Top Up</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Please enter transaction hash to retrieve failed top up
            </DialogContentText>
            <TextField
              error={!!error}
              fullWidth
              name="txHash"
              placeholder="Transaction Hash"
            />
            <Typography mt={2} mb={1} variant="body2">
              Select Asset
            </Typography>
            <AssetSelect
              disabled={
                loading || !topupInfo.topupAddress || error === "unavailable"
              }
              value={topupAsset}
              onChange={(e, v) => setTopupAsset(v)}
            />
            {!!error && (
              <Typography mt={2} color={(theme) => theme.palette.error.main}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 1 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={loading} size="large" type="submit">
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AlertModal
        open={!!success}
        onClose={() => setSuccess("")}
        title="Success"
        description={success}
        type="success"
      />
    </>
  );
};

export default RetrieveTopUpModal;
