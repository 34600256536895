export const cardImages: any = {
  blue: require("../assets/img/cards/blue.png"),
  silver: require("../assets/img/cards/silver.png"),
  black: require("../assets/img/cards/black.png"),
};

export const cardColors: any = {
  blue: "#0C82C4",
  silver: "#C4C4C4",
  black: "#000000",
};
