import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import NodeRSA from "node-rsa";
import { useAccount } from "./account";

export interface SensitiveInformation {
  cardNumber: string;
  cvv: string;
  password: string;
}

const emptySensitiveInformation: SensitiveInformation = {
  cardNumber: "",
  cvv: "",
  password: "",
};

export const sensitiveInformationState = atom<SensitiveInformation>({
  key: "sensitiveInformation",
  default: emptySensitiveInformation,
});

export const useSensitiveInformation = () => {
  const { account } = useAccount();
  const [sensitiveInformation, setSensitiveInformationState] = useRecoilState(
    sensitiveInformationState
  );

  const fetchSensitiveInformation = useCallback(async () => {
    if (!window.keplr) {
      throw new Error("keplr not installed");
    }
    const timestamp = new Date().toISOString();
    const signed = await window.keplr.signArbitrary(
      account.chainId,
      account.address,
      `Fina Card: View Sensitive Information at ${timestamp}`
    );
    const key = new NodeRSA();
    key.generateKeyPair(1024);
    const publicKey = Buffer.from(key.exportKey("pkcs1-public")).toString(
      "base64"
    );
    const result = await fetch(
      `${process.env.NEXT_PUBLIC_CARD_API_URL}/getSensitiveInfo`,
      {
        method: "POST",
        body: JSON.stringify({
          ...account,
          signature: signed.signature,
          timestamp,
          rsaPubKey: publicKey,
        }),
      }
    ).then((r) => r.json());
    if (result.success === false) {
      throw new Error(result.message);
    }
    key.setOptions({
      encryptionScheme: {
        hash: "sha256",
        label: "CardNumber",
      } as any,
    });
    const cardNumber = key.decrypt(
      result.encryptedCardNumber.split("\n").slice(1, -2).join(""),
      "utf8"
    );
    key.setOptions({
      encryptionScheme: {
        hash: "sha256",
        label: "CVV2",
      } as any,
    });
    const cvv = key.decrypt(
      result.encryptedCvv.split("\n").slice(1, -2).join(""),
      "utf8"
    );
    key.setOptions({
      encryptionScheme: {
        hash: "sha256",
        label: "Card3DSecurePassword",
      } as any,
    });
    const password = key.decrypt(
      result.encrypted3dsPassword.split("\n").slice(1, -2).join(""),
      "utf8"
    );
    setSensitiveInformationState({
      cardNumber: cardNumber.match(/.{1,4}/g)!.join(" "),
      cvv,
      password,
      // pin: key.decrypt(
      //   result.encryptedPin.split("\n").slice(1, -2).join(""),
      //   "utf8"
      // ),
    });
  }, [account, setSensitiveInformationState]);

  const resetSensitiveInformation = useCallback(() => {
    setSensitiveInformationState(emptySensitiveInformation);
  }, [setSensitiveInformationState]);

  return {
    sensitiveInformation,
    fetchSensitiveInformation,
    resetSensitiveInformation,
  };
};
