import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC } from "react";
import { Transaction, useCard } from "../../models/card";
import { formatNumber } from "../../utils/formatNumber";
import useIsMobile from "../../utils/useIsMobile";
import TransactionItem from "./TransactionItem";

interface Props {
  open: boolean;
  onClose: () => void;
  transaction?: Transaction;
}

const feeNames: any = {
  AuthorizationForeignExchangePercentageFee: "Exchange Fee",
};

const TransactionModal: FC<Props> = ({ open, onClose, transaction }) => {
  const isMobile = useIsMobile();
  const { card } = useCard();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Transaction Details</DialogTitle>
      {!!transaction && (
        <DialogContent>
          <TransactionItem
            type={transaction.amount > 0 ? "topup" : "spend"}
            name={
              transaction.amount > 0
                ? transaction.description
                : transaction.merchant.name
            }
            longName={
              transaction.merchant.name === transaction.merchant.longName
                ? ""
                : transaction.merchant.longName
            }
            icon={transaction.merchant.icon}
            amount={Math.abs(transaction.amount)}
            date={transaction.date}
            hideBorder
            hideAmount
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            my={3}
          >
            <Typography variant="body2">Date</Typography>
            <Typography variant="h6">
              {format(transaction.date, "yyyy-MM-dd, h:mm a")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={3}
          >
            <Typography variant="body2">Status</Typography>
            <Typography variant="h6">{transaction.status}</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={3}
          >
            <Typography variant="body2">Tx Amount</Typography>
            <Typography
              variant="h6"
              color={
                transaction.amount > 0
                  ? (theme) => theme.palette.success.light
                  : undefined
              }
            >
              {transaction.amount > 0 ? "" : "- "}
              {transaction.fx.currency}{" "}
              {formatNumber(Math.abs(transaction.fx.amount))}
            </Typography>
          </Stack>
          {transaction.fee.map((f) => (
            <Stack
              key={f.description}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              mb={3}
            >
              <Typography variant="body2">{feeNames[f.description]}</Typography>
              <Typography variant="h6">
                - {f.currency} {formatNumber(Math.abs(f.amount))}
              </Typography>
            </Stack>
          ))}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={3}
          >
            <Typography variant="body2">Total Amount</Typography>
            <Typography
              variant="h6"
              color={
                transaction.amount > 0
                  ? (theme) => theme.palette.success.light
                  : undefined
              }
            >
              {transaction.amount > 0 ? "" : "- "}
              {card.currency} {formatNumber(Math.abs(transaction.amount))}
            </Typography>
          </Stack>
          {transaction.hash && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography variant="body2">Tx Hash</Typography>
              <Typography variant="h6">
                <Link
                  href={`${process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/txs/${transaction.hash}`}
                  target="_blank"
                >
                  {transaction.hash.slice(0, isMobile ? 5 : 8) +
                    (isMobile ? "..." : "......") +
                    transaction.hash.slice(isMobile ? -5 : -8)}
                </Link>
              </Typography>
            </Stack>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" size="large" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionModal;
