import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Link,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDownward, Info } from "@mui/icons-material";
import { get } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useAccount } from "../../../models/account";
import { useTopupInfo } from "../../../models/topupInfo";
import { formatNumber, formatPercentage } from "../../../utils/formatNumber";
import { useCard } from "../../../models/card";
import AlertModal from "../../AlertModal";
import RetrieveTopUpModal from "./RetrieveTopUpModal";
import AssetSelect, { assets } from "./AssetSelect";
import useIsMobile from "../../../utils/useIsMobile";
import { SecretNetworkClient } from "secretjs";
import { useFinaTokenInfo } from "../../../models/finaTokenInfo";
import StakeModal from "../../StakingSection/StakeModal";

// TODO: selectable asset
const currency = "EUR";

const TopupModal: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const isMobile = useIsMobile();
  const { account } = useAccount();
  const { fetchCard, topup, card } = useCard();
  const [amount, setAmount] = useState("");
  const [currencyAmount, setCurrencyAmount] = useState("");
  const [balance, setBalance] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [retrieveTopupModalOpen, setRetrieveTopupModalOpen] = useState(false);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);
  const [topupAsset, setTopupAsset] = useState(assets[0]);

  const { topupInfo, fetchTopupInfo, resetTopupInfo } = useTopupInfo();
  const { stakingInfo, finaToken, getFinaToken, getStakingInfo } =
    useFinaTokenInfo();

  const missingStakingAmount =
    stakingInfo.finaCardTiers?.tiers?.[card?.tier?.name]
      ?.stakingFinaRequirements - stakingInfo.staked;

  const requiredStaking =
    !stakingInfo.finaCardTiers?.myTier?.whitelisted &&
    missingStakingAmount > 100; // buffer for decimal error

  const error = useMemo(
    () =>
      topupInfo.minTopupAmount > topupInfo.maxTopupAmount
        ? "unavailable"
        : amount && Number(amount) < topupInfo.minTopupAmount
        ? "min"
        : amount && Number(amount) > topupInfo.maxTopupAmount
        ? "max"
        : balance !== undefined && amount && Number(amount) > balance
        ? "not enough balance"
        : !amount
        ? "empty"
        : "",
    [amount, topupInfo, balance, stakingInfo, card]
  );

  useEffect(() => {
    if (open) {
      resetTopupInfo();
      setAmount("");
      setBalance(undefined);
      fetchTopupInfo({
        chainId: account.chainId,
        topupAsset: topupAsset.symbol,
        currency,
      });
    } else {
      resetTopupInfo();
      setAmount("");
      setBalance(undefined);
      setLoading(false);
    }
  }, [open, topupAsset]);

  useEffect(() => {
    if (topupInfo.topupAddress && ["native", "ibc"].includes(topupInfo.type)) {
      fetch(
        `${process.env.NEXT_PUBLIC_SECRET_LCD_URL}/cosmos/bank/v1beta1/balances/${account.address}`
      )
        .then((r) => r.json())
        .then((r) => {
          setBalance(
            Number(
              get(
                get(r, ["balances"], []).find(
                  (b: any) => b.denom === topupInfo.denom
                ),
                ["amount"],
                "0"
              )
            ) /
              10 ** topupInfo.decimals
          );
        });
    } else if (topupInfo.topupAddress && topupInfo.type === "snip20") {
      viewSnip20Balance(false);
    }
  }, [topupInfo, account]);

  const viewSnip20Balance = async (shouldSuggestToken: boolean) => {
    try {
      const key = await window.keplr?.getSecret20ViewingKey(
        account.chainId,
        topupInfo.denom
      );
      const secretjs = new SecretNetworkClient({
        url: process.env.NEXT_PUBLIC_SECRET_LCD_URL || "",
        chainId: account.chainId,
        walletAddress: account.address,
      });
      const result: any = await secretjs.query.compute.queryContract({
        contract_address: topupInfo.denom,
        code_hash: topupInfo.codeHash,
        query: { balance: { key, address: account.address } },
      });
      if (result.viewing_key_error) {
        // TODO: display error
        setErrorMessage(result.viewing_key_error.msg);
      } else {
        setBalance(
          Number(get(result, "balance.amount", "0")) / 10 ** topupInfo.decimals
        );
      }
    } catch (err) {
      if (shouldSuggestToken) {
        await window.keplr?.suggestToken(account.chainId, topupInfo.denom);
        viewSnip20Balance(false);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        fullScreen={isMobile}
      >
        <DialogTitle align="center">Top Up Fina Card</DialogTitle>
        <DialogContent>
          <Typography mb={1} variant="body2">
            Select Asset
          </Typography>
          <AssetSelect
            disabled={
              loading || !topupInfo.topupAddress || error === "unavailable"
            }
            value={topupAsset}
            onChange={(e, v) => setTopupAsset(v)}
          />
          <Stack mt={4} mb={1} direction="row" justifyContent="space-between">
            <Typography variant="body2">Amount</Typography>
            <Typography variant="body2">
              {topupInfo.topupAddress ? (
                <Link
                  underline="hover"
                  sx={{ cursor: "pointer", ml: 1 }}
                  onClick={() => {
                    if (
                      loading ||
                      !topupInfo.topupAddress ||
                      error === "unavailable"
                    ) {
                      return;
                    }
                    if (topupInfo.type === "snip20" && balance === undefined) {
                      viewSnip20Balance(true);
                      return;
                    }
                    setAmount(
                      String(Math.max((balance || 0) - topupInfo.gasBuffer, 0))
                    );
                    setCurrencyAmount(
                      formatNumber(
                        ((balance || 0) - topupInfo.gasBuffer) * topupInfo.rate,
                        2
                      )
                    );
                  }}
                >
                  {topupInfo.type === "snip20" && balance === undefined
                    ? "View Balance"
                    : `Available: ${formatNumber(balance || 0)} ${
                        topupAsset.symbol
                      }`}
                </Link>
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Stack>
          <TextField
            value={amount}
            error={!!error && error !== "empty"}
            disabled={
              loading || !topupInfo.topupAddress || error === "unavailable"
            }
            onChange={(e) => {
              if (
                !e.target.value.match(/[^.0-9]/) &&
                (e.target.value.match(/\./g) || []).length < 2
              ) {
                setAmount(e.target.value);
                setCurrencyAmount(
                  formatNumber(Number(e.target.value) * topupInfo.rate, 2)
                );
              }
            }}
            placeholder="0.0"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {topupAsset.symbol}
                </InputAdornment>
              ),
            }}
          />
          <Stack alignItems="center" my={-1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={(theme) => theme.palette.primary.main}
              color={(theme) => theme.palette.background.default}
              borderRadius="50%"
              p={2}
              zIndex={1}
            >
              <ArrowDownward />
            </Stack>
          </Stack>
          <TextField
            value={currencyAmount}
            placeholder="0.0"
            fullWidth
            disabled={
              loading || !topupInfo.topupAddress || error === "unavailable"
            }
            onChange={(e) => {
              if (
                !e.target.value.match(/[^.0-9]/) &&
                (e.target.value.match(/\./g) || []).length < 2
              ) {
                setCurrencyAmount(e.target.value);
                setAmount(
                  formatNumber(Number(e.target.value) / topupInfo.rate, 4)
                );
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{currency}</InputAdornment>
              ),
            }}
          />
          <Stack mt={1} mb={6} direction="row" justifyContent="flex-end">
            <Typography variant="body2">
              {topupInfo.topupAddress ? (
                <>
                  1 {topupAsset.symbol} = {formatNumber(topupInfo.rate, 4)}{" "}
                  {currency}
                </>
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={3}
          >
            <Typography variant="body2">
              Fee ({formatPercentage(topupInfo.feeRate)})
            </Typography>
            <Typography fontWeight={500}>
              {topupInfo.topupAddress ? (
                <>
                  {formatNumber(
                    topupInfo.feeRate * Number(amount) * topupInfo.rate,
                    2
                  )}{" "}
                  {currency}
                </>
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mb={3}
          >
            <Typography variant="body2">
              Top Up Amount{" "}
              <Tooltip
                placement="top"
                title="This is an estimated amount. Small slippage may occur during top up."
              >
                <Info sx={{ mb: -0.8 }} />
              </Tooltip>
            </Typography>
            <Typography fontWeight={500}>
              {topupInfo.topupAddress ? (
                <>
                  {formatNumber(
                    (1 - topupInfo.feeRate) * Number(amount) * topupInfo.rate,
                    2
                  )}{" "}
                  {currency}
                </>
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Stack>
          {error === "min" && (
            <Typography color={(theme) => theme.palette.error.main}>
              Minimum top up amount is{" "}
              {formatNumber(topupInfo.minTopupAmount, 4)} {topupAsset.symbol}
            </Typography>
          )}
          {error === "max" && (
            <Typography color={(theme) => theme.palette.error.main}>
              Maximum top up amount is{" "}
              {formatNumber(topupInfo.maxTopupAmount, 4)} {topupAsset.symbol}
            </Typography>
          )}
          {error === "not enough balance" && (
            <Typography color={(theme) => theme.palette.error.main}>
              Not enough balance
            </Typography>
          )}
          {error === "unavailable" && (
            <Typography color={(theme) => theme.palette.error.main}>
              Top Up is temporarily unavailable
            </Typography>
          )}
          {requiredStaking && (
            <Typography color={(theme) => theme.palette.error.main}>
              You don&apos;t have enough FINA token staked
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column-reverse" }}>
          <Button
            variant="text"
            size="large"
            onClick={() => setRetrieveTopupModalOpen(true)}
          >
            Top Up failed? Retrieve it here
          </Button>
          <Stack
            direction="row"
            mb={2}
            width="100%"
            justifyContent="space-around"
          >
            <Button
              sx={{ width: 150 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: 150 }}
              disabled={
                !requiredStaking &&
                (!!error || loading || balance === undefined)
              }
              size="large"
              onClick={async () => {
                if (requiredStaking) {
                  setIsStakeModalOpen(true);
                  return;
                }
                setLoading(true);
                try {
                  const result = await topup(
                    topupInfo,
                    amount,
                    topupAsset.symbol,
                    currency
                  );
                  await fetchCard();
                  setLoading(false);
                  onClose();
                  setSuccessMessage(
                    `You have successfully topped up ${
                      result.currency
                    } ${formatNumber(result.topupValue)} to your Fina Card!`
                  );
                } catch (err: any) {
                  setLoading(false);
                  setErrorMessage(err.message);
                }
              }}
            >
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : requiredStaking ? (
                "Stake Now"
              ) : (
                "Top Up"
              )}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <AlertModal
        open={!!successMessage || !!errorMessage}
        onClose={() => {
          setSuccessMessage("");
          setErrorMessage("");
        }}
        title={!!successMessage ? "Success" : "Error"}
        description={successMessage || errorMessage}
        type={!!successMessage ? "success" : "error"}
      />
      <RetrieveTopUpModal
        open={retrieveTopupModalOpen}
        onClose={() => setRetrieveTopupModalOpen(false)}
        topupAsset={topupAsset}
        setTopupAsset={setTopupAsset}
        currency={currency}
        topupInfo={topupInfo}
      />
      <StakeModal
        open={isStakeModalOpen}
        onClose={() => setIsStakeModalOpen(false)}
        max={finaToken.balance}
        lockUpDays={stakingInfo.lockUpDays}
        onSuccess={async () => {
          await getFinaToken(true);
          await getStakingInfo(true);
        }}
        defaultAmount={missingStakingAmount || undefined}
      />
    </>
  );
};

export default TopupModal;
